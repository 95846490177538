<template>
  <h1>404 — Not Found</h1>
</template>

<script>
export default {
  name: "NotFound"
}

window.location.replace("/404");
</script>